import React, { Component } from 'react'
import { Input, Icon, Button, Upload, Drawer,  DatePicker, TimePicker } from 'antd';
import moment from 'moment';
import {url} from '../utils/request'
const { RangePicker } = DatePicker;
const { TextArea } = Input;

export default class OnedoAdd extends Component {
    constructor(props) {
        super(props);
        this.state = {
            describtion: '',
            workTitle: '',//工作流标题
            datacreate: '',
            timecreate: "",
            planTimestart: null,
            planTimeend: null,
            fileList: []

        }
    }
    componentDidMount() {
        let data = new Date();
        this.state.datacreate = data.getFullYear() + '-' + (data.getMonth() + 1) + '-' + data.getDate();
        this.state.timecreate = data.getHours() + ':' + data.getMinutes() + ':' + data.getSeconds();
        this.setState({})
    }
    onChangeInput = (e) => {
        this.setState({ describtion: e.target.value })
    }
    changeTime = (dates, dateStrings) => {
        this.setState({
            planTimestart: dateStrings[0],
            planTimeend: dateStrings[1],
        })
    }
    handleChangeFile = (info) => {
        if (info.file.status !== 'uploading') {
            this.setState({ fileList: info.fileList.map(item => ({ annexUrl: item.response.data, annexName: item.name })) })
        }
    }
    render() {
        const { visible, launch, participants, workTitle, onClose, addprocess, reduceIndex, addImg, reduceImg, submit } = this.props
        const { describtion, planTimestart, planTimeend, fileList } = this.state
        const props = {
            name: 'file',
            action: `${url}/upload`,
            headers: {
            },
            onChange: this.handleChangeFile,
        };
        return (
            <div>
                <Drawer
                    title="新建1do"
                    placement="right"
                    closable={true}
                    width={'40%'}
                    className='addonedo'
                    onClose={onClose}
                    visible={visible}
                >
                    <div className='toptitle'>
                        {launch && launch.map((item, index) => {
                            return <img key={index} src={`${url}/yidoBase/avatar?showId=${item.showId}`} />
                        })}
                        <TextArea rows={4} value={describtion} onChange={(e) => { this.onChangeInput(e) }} className='TextArea' />
                    </div>
                    <div className='process'>
                        <span>流程</span>
                        {workTitle ? workTitle : <Button type="link" onClick={addprocess}>添加工作流</Button>}
                    </div>
                    <div className='imgaction'>
                        <Icon type="user" className='user' />
                        <div className='imglist'>
                            {participants && participants.map((item, index) => {
                                return <span className='imgspan' key={index}>
                                    <img  src={`${url}/yidoBase/avatar?showId=${item.showId}`} />
                                    {item.show && <Icon onClick={() => { reduceIndex(index) }} className="minus" type="minus-circle" />}
                                </span>
                            })}
                        </div>
                        <div className='actions'>
                            <Icon type="plus-circle" onClick={addImg} />
                            <Icon type="minus-circle" onClick={reduceImg} />
                        </div>
                    </div>
                    <div className='createTime'>
                        <span className="title">创建时间</span>
                        <DatePicker defaultValue={moment(this.state.datacreate, 'YYYY-MM-DD')} disabled />
                        <TimePicker defaultValue={moment(this.state.timecreate, 'HH:mm:ss')} disabled />
                    </div>
                    <div className='planTime'>
                        <span className="title">计划时间</span>
                          <RangePicker
                            allowClear
                            value={[planTimestart ? moment(planTimestart) : null, planTimeend ? moment(planTimeend) : null]}
                            onChange={(dates, dateStrings) => { this.changeTime(dates, dateStrings) }}
                        />            
                    </div>
                    <div className='upload'>
                        <Upload {...props}>
                            <Button>
                                <Icon type="upload" /> 上传附件
                            </Button>
                        </Upload>
                    </div>
                    <div className='buttons'>
                        <Button onClick={onClose}>取消</Button>
                        <Button type='primary' onClick={() => { submit(describtion, fileList, planTimestart, planTimeend) }}>确定</Button>
                    </div>
                </Drawer>
            </div>
        )
    }
}
