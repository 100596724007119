import React, { Component } from 'react'
import { Input, Button, message,Table } from 'antd';
import { systemRegistration ,GetQueryString} from '../../services/index'
import './index.scss'
const columns=[{
    title: '来源ID(sourceId)',
    dataIndex: 'sourceId',
    key: 'sourceId',
},{
    title: '来源名称(sourceName)',
    dataIndex: 'sourceName',
    key: 'sourceName',
},{
    title: '来源分类ID(sourceEventId)',
    dataIndex: 'sourceEventId',
    key: 'sourceEventId',
},{
    title: '来源分类名称(sourceEventName)',
    dataIndex: 'sourceEventName',
    key: 'sourceEventName',
},{
    title: '来源地址(sourceUrl)',
    dataIndex: 'sourceUrl',
    key: 'sourceUrl',
},{
    title: 'token',
    dataIndex: 'token',
    key: 'token',
}]
export default class Register extends Component {
    constructor(props) {
        super(props);
        this.state = {
            sourceName: "",
            sourceUrl: "",
            showlist: false,
            result: "",
            data:[]
        }
    }
    componentDidMount(){
        localStorage.setItem('showId',GetQueryString('showId'))
        localStorage.setItem('token',GetQueryString('token'))
    }
    register = () => {
        const { sourceName, sourceUrl } = this.state
        console.log(sourceName, sourceUrl);
        if (!sourceName) {
            message.warning('请填写来源名称!');
            return
        }
        if (!sourceUrl) {
            message.warning('请填写来源回调地址!');
            return
        }
        systemRegistration({
            sourceName, sourceUrl
        }).then(res => {
            console.log(res);
            if (res.status === 0) {
                message.success(res.description);
                this.setState({
                    sourceName: '', sourceUrl: '', showlist: true, data: [res.data]
                })
            } else {
                message.error(res.description);
            }
        })
    }
    changeText = (key, e) => {
        this.state[key] = e.target.value
        this.setState({})
    }
    render() {
        const { sourceName, sourceUrl, showlist, result ,data} = this.state
        return (
            <div className='register'>
                {!showlist && <div>
                    <p className='title'>系统注册</p>
                    <div className='input'>
                        <span>来源名称 : </span><Input value={sourceName} placeholder="输入来源名称" onChange={(e) => { this.changeText('sourceName', e) }} /><br />
                    </div>
                    <div className='input'>
                        <span>来源回调地址 : </span><Input value={sourceUrl} placeholder="输入来源回调地址" onChange={(e) => { this.changeText('sourceUrl', e) }} />
                    </div>
                    <Button type="primary" onClick={this.register}>注册</Button>
                </div>}
                {showlist && <div>
                    <p className='title'>注册成功！</p>
                    <div className='result'>
                        <span style={{color:'#000'}}>返回值 : </span><br />
                        <Table columns={columns} dataSource={data} pagination={false} />
                    </div>
                </div>}
            </div>
        )
    }
}
