import React, { Component } from 'react'
import { Input,  Checkbox, Modal, } from 'antd';
const CheckboxGroup = Checkbox.Group;

export default class Choosepeople extends Component {
    render() {
        const { peoplevisible,indeterminate,plainOptions,checkAll,checkedList,onCheckAllChange,onChange,handleCancelPeoplevisible,handleOkPeoplevisible,onChangepeople} = this.props

        return (
            <div>
                <Modal
                    title=""
                    visible={peoplevisible}
                    onOk={handleOkPeoplevisible}
                    className='worklist'
                    onCancel={handleCancelPeoplevisible}
                >
                    <Input placeholder="请输入内容" allowClear onChange={(e) => { onChangepeople(e) }} style={{ width: '90%' }} /><br />
                    <Checkbox
                        indeterminate={indeterminate}
                        onChange={onCheckAllChange}
                        checked={checkAll}
                    >
                        全选
                   </Checkbox>
                    <br />
                    <CheckboxGroup
                        options={plainOptions}
                        value={checkedList}
                        onChange={onChange}
                    />
                </Modal>
            </div>
        )
    }
}
