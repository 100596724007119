import React, { Component } from 'react'
import { BrowserRouter as Router,Route } from "react-router-dom";
import Home from '../pages/home'
import Add from '../pages/add/index'
import OnedoDetail from '../pages/detail/index'
import Register from '../pages/register/index'
import H5 from '../pages/h5/index'
import ListDetail from '../pages/h5/listDetail' 
import H5add from '../pages/h5/add'
import Detail from '../pages/h5/detail'
import ChoosePeople from '../pages/h5/choosePeople'
export default class RouteIndex extends Component {
    render() {
        return (
            <Router>
                 <Route path="/" exact component={Home}></Route>
                 <Route path="/add" component={Add}></Route>
                 <Route path="/detail" component={OnedoDetail}></Route>
                 <Route path="/register" component={Register}></Route>
                {/*  h5 */}
                 <Route path="/H5" component={H5}></Route>
                 <Route path="/list" component={ListDetail}></Route>
                 <Route path="/appAdd" component={H5add}></Route>
                 <Route path="/appDetail" component={Detail}></Route>
                 <Route path="/choosePeople" component={ChoosePeople}></Route>
            </Router>
        )
    }
}
