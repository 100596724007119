import React, { Component } from 'react'
import { Icon, Input, message, Button,DatePicker } from 'antd';
import moment from 'moment';
import { url } from '../../utils/request'
import WorkFlow from '../../components/workFlow'
import Choosepeople from '../../components/choosepeople'
import { modfiyDetail, GetQueryString, getDeatil, addLabel, deleteLabel, getworkflow,getWorkProcess,getpeopleBywork,addWorkflow,regUserList, updateStatus } from '../../services/index'

import './index.scss'
const { TextArea } = Input;
const { RangePicker } = DatePicker;
export default class OnedoDetail extends Component {
    constructor(props) {
        super(props);
        this.state = {
            gongshowId: "",//工单id
            launch: "",
            describtion: "",
            launch: [],
            participants: [],
            labellist: [],
            tagname: "",
            processTitle: '',
            nodes: "",
            workList:"",
            Modalvisible:false,
            workTitle:'',
            tempId:"",
            indeterminate: true,
            plainOptions: [],
            checkAll: false,
            checkedList: [],
            startTime: null,
            endTime: null,
            title: "",
            status: '',
        }
    }
    componentDidMount() {
        console.log(GetQueryString('doId'));
        this.setState({ gongshowId: GetQueryString('doId') })
        this.setImgInit()
        this.getDetail()
        this.getworkflow()
    }
    getDetail() {
        getDeatil({ showId: GetQueryString('doId') }).then(res => {
            if (res.status === 0) {
                this.state.labellist = res.data.labelList
                this.state.launch = [...this.state.launch, ...res.data.sponsorMapList.map(item => ({ showId: item.userId }))]
                this.state.participants = [... this.state.participants, ...res.data.participantsMapList.map(item => ({ showId: item.userId }))]
                this.state.startTime=  res.data.startTime 
                this.state.endTime= res.data.endTime 
                this.state.describtion=  res.data.describtion 
                this.state.title= res.data.title 
                this.state.status= res.data.status 
                // this.state.processTitle  = res.data.process? res.data.process.entity.tempName :'' 
                this.setState({})
            }
        })
    }
    getworkflow() {
        getworkflow({ showId: GetQueryString('doId') }).then(res => {
            if (res.status === 0) {
                console.log(res.data);
                // this.setState({ process: res.data })
                this.setState({ processTitle: res.data ? res.data.entity.tempName : '' })
                this.setState({ nodes: res.data ? res.data.nodes : '' })
            } else if (res.status === 4101) {
                this.setState({ process: '' })
            }
        })
    }
    setImgInit() {
        let showId = GetQueryString('showId')
        localStorage.setItem('showId', showId)
        // this.state.launch.push({ showId })
        // this.state.participants.push({ showId })
        this.setState({})
    }
    onClose = () => {
        window.parent.postMessage({ type: false }, "*");
    }
    onChangeInput = (e) => {
        this.setState({ describtion: e.target.value }, () => {
            this.modfiyDetail({ describtion: e.target.value })
        })
    }
    onChangeInput1 = (e) => {
        this.setState({ describtion: e.target.value })
    }
    modfiyDetail = ({ describtion, flag, userId, startTime, endTime, title }) => {
        let data = {
            showId: this.state.gongshowId,
            describtion: describtion,
            flag,
            userId: userId && userId.map(item => item.showId),
            startTime,
            endTime,
            title
        }
        modfiyDetail(data).then(res => {
            if (res.status === 0) {
                message.success(res.description);
            }
        })
    }
    reduceTags = (id, index) => {
        deleteLabel({ id }).then(res => {
            if (res.status === 0) {
                this.state.labellist.splice(index, 1)
                message.success(res.description);
                this.setState({})
            }
        })
    }
    onChangeTags = (e) => {
        this.setState({ tagname: e.target.value })
    }
    addtags = () => {
        let data = {
            labelName: this.state.tagname,
            showId: this.state.gongshowId
        }
        addLabel(data).then(res => {
            if (res.status === 0) {
                this.setState({ tagname: "" })
                this.state.labellist.push(res.data)
                this.setState({})
                message.success(res.description);
            } else {
                message.warning(res.description);
            }
        })

    }
    addprocess = () => {
        this.setState({ Modalvisible: true })
        this.getWorkProcess()
    }
    getWorkProcess() {
        let data = {
            pageNum: 1,
            pageSize: 20,
            workflowStatus: 0
        }
        getWorkProcess(data).then(res => {
            if (res.status === 0) {
                this.setState({ workList: res.data.list })
            }
        })
    }
    getStatus = ({ clazz, nodeStatus }) => {
        if (clazz === 'start') {
            return '发起'
        } else if (clazz === 'userTask') {
            if (nodeStatus === 0) {
                return '待审批'
            } else if (nodeStatus === 1) {
                return '进行中'
            } else if (nodeStatus === 2) {
                return '已完成'
            }
        }
    }
    render() {
        const { launch, describtion, participants,labellist, tagname, processTitle, nodes,Modalvisible,workList,peoplevisible,startTime,endTime,title,status } = this.state
        return (
            <div className='detailSlider'>
                <div className='title'>1do详情
                    <Icon type="close" onClick={this.onClose} />
                </div>
                <div className='toptitle detailTitle'>
                    {launch && launch.map((item, index) => {
                        return <img key={index} src={`${url}/yidoBase/avatar?showId=${item.showId}`} />
                    })}
                    <TextArea
                        value={describtion}
                        rows={4}
                        onChange={(e) => { this.onChangeInput1(e) }}
                        onBlur={(e) => { this.onChangeInput(e) }}
                        className='TextArea' />
                </div>
                <div className='labelDetail'>
                    <span className='title'>标签</span>
                    {labellist && labellist.map((item, index) => {
                        return <span className='tags' key={item.id}>{item.labelName}<Icon type="close" onClick={() => { this.reduceTags(item.id, index) }} /></span>
                    })}
                    <Input value={tagname} onPressEnter={this.addtags} onChange={(e) => { this.onChangeTags(e) }} style={{ width: '80%' }} />
                    <Button type="primary" onClick={this.addtags}>添加</Button>
                </div>
                <div className='processDetail'>
                    <span className='title'>流程</span>
                    <div className='rightData'>
                        <p>{processTitle}</p>
                        <div>
                            {nodes ? nodes.map((item, index) => {
                                console.log(index);
                                if (item.clazz === 'endGreen') {
                                    return <span key={index} className='finishspan'>结束</span>
                                } else {
                                    return <span key={index} className='content'>
                                        {item.approvalUser.length > 0 && item.approvalUser.map((v, i) => {
                                            return <span key={i} >
                                                <img src={`${url}/yidoBase/avatar?showId=${v.showId}`} />
                                                <Icon type="arrow-right" />
                                                <span className='trip'>{this.getStatus(item)}</span>
                                            </span>
                                        })}
                                    </span>
                                }
                            }) : <Button type="link" onClick={this.addprocess}>添加工作流</Button>}
                        </div>
                    </div>

                </div>
                <div className='imgaction detailImg'>
                        <Icon type="user" className='user' />
                        <div className='imglist'>
                            {participants && participants.map((item, index) => {
                                return <span className='imgspan' key={index}>
                                    <img src={`${url}/yidoBase/avatar?showId=${item.showId}`} />
                                    {item.show && <Icon onClick={() => { this.reduceIndex(index) }} className="minus" type="minus-circle" />}
                                </span>
                            })}
                        </div>
                        <div className='actions'>
                            <Icon type="plus-circle" onClick={this.addImg} />
                            <Icon type="minus-circle" onClick={this.reduceImg} />
                        </div>
                </div>
                <div className='planTime'>
                        <span className="title">计划时间</span>
                        <RangePicker
                            allowClear
                            value={[startTime ? moment(startTime) : null, endTime ? moment(endTime) : null]}
                            onChange={(dates, dateStrings) => { this.changeTime(dates, dateStrings) }}
                        />
                </div>
                <div className='titleData'>
                    <span className="title">标题</span>
                    <Input value={title} onChange={(e) => { this.onChangeTitle(e) }} style={{ width: '60%' }} />
                    <Button type="primary" onClick={this.updataTitle}>完成</Button>
                </div>
                <div className='handle'>
                        {/* <Button onClick={this.props.addChildOnedo}>新建下级1do</Button> */}
                        <Button type="primary" disabled={status === 3 ? true : false} onClick={this.conclude}>{status === 3 ? '已办结' : "办结"}</Button>
                    </div>
                {Modalvisible && <WorkFlow
                    Modalvisible={Modalvisible}
                    workList={workList}
                    addWork={this.addWork}
                    handleOk={this.handleOk}
                    handleCancel={this.handleCancel}
                />}
                {peoplevisible && <Choosepeople
                    peoplevisible={peoplevisible}
                    indeterminate={this.state.indeterminate}
                    plainOptions={this.state.plainOptions}
                    checkAll={this.state.checkAll}
                    checkedList={this.state.checkedList}
                    onCheckAllChange={this.onCheckAllChange}
                    onChange={this.onChange}
                    handleCancelPeoplevisible={this.handleCancelPeoplevisible}
                    handleOkPeoplevisible={this.handleOkPeoplevisible}
                    onChangepeople={this.onChangepeople}
                />}
            </div>
        )
    }
    conclude = () => {
        let data = {
            showId: this.state.gongshowId,
            status: 1
        }
        updateStatus(data).then(res => {
            if (res.status === 0) {
                message.success(res.description);
                this.setState({ status: 3 })
            }
        })
    }
    updataTitle = () => {
        this.modfiyDetail({ title: this.state.title })
    }
    onChangeTitle = (e) => {
        this.setState({ title: e.target.value })
    }
    changeTime = (dates, dateStrings) => {
        this.setState({
            startTime: dateStrings[0],
            endTime: dateStrings[1],
        }, () => {
            this.modfiyDetail({ startTime: dateStrings[0], endTime: dateStrings[1] })
        })
    }
    onChangepeople = (e) => {
        if (this.interal) {
            clearTimeout(this.interal)
        }
        this.interal = setTimeout(() => {
            this.getpeople(e.target.value)
        }, 500)
    }
    handleOkPeoplevisible = () => {
        let peoples = this.state.checkedList.map(item => ({ showId: item }))
        this.state.participants = this.unique([... this.state.participants, ...peoples]) //需要对participants去重
        this.setState({ peoplevisible: false })
        // ------------
        //详情里添加人员，只传递了新增的人员
        // if (this.state.detailVisible) {
            this.modfiyDetail({ flag: 1, userId: peoples })
        // }
    }
    handleCancelPeoplevisible = () => {
        this.setState({ peoplevisible: false })
    }
    onCheckAllChange = e => {
        this.setState({
            checkedList: e.target.checked ? this.state.plainOptions.map(item => item.value) : [],
            indeterminate: false,
            checkAll: e.target.checked,
        }, () => {
            console.log(this.state.checkedList);
        });
    };

    onChange = (checkedList) => {
        this.setState({
            checkedList,
            indeterminate: !!checkedList.length && checkedList.length < this.state.plainOptions.length,
            checkAll: checkedList.length === this.state.plainOptions.length,
        }, () => {
            console.log(this.state.checkedList);
        });
    };
    unique = (arr) => {
        var array = [];
        for (var i = 0; i < arr.length; i++) {
            if (array.length > 0) {
                if (array.map(item => item.showId).indexOf(arr[i].showId) === -1) {
                    array.push(arr[i])
                }
            } else {
                array.push(arr[0])
            }
        }
        return array;
    }
    addImg = () => {
        this.setState({ peoplevisible: true, checkedList: [], })
        this.getpeople()
    }
    reduceImg = () => {
        this.state.participants = this.state.participants.map(item => {
            return { ...item, show: !item.show }
        })
        console.log(this.state.participants)
        this.setState({})
    }
    reduceIndex = (index) => {
        console.log(this.state.participants[index]);
        //详情里删除人员，
        // if (this.state.detailVisible) {
            this.modfiyDetail({ flag: 2, userId: [this.state.participants[index]] })
        // }
        // ------------
        this.state.participants.splice(index, 1)
        console.log(this.state.participants);
        this.setState({})
    }
    getpeople = (word = '') => {
        let data = {
            "pageNum": 1,
            "pageSize": 100,
            word
        }
        regUserList(data).then(res => {
            if (res.status === 0) {
                this.setState({
                    plainOptions: res.data.list.map(item => ({ label: item.name, value: item.showId }))
                })
            }
        })
    }
    addWork = (id, workTitle) => {
        getpeopleBywork({ id }).then(res => {
            if (res.status === 0) {
                this.state.participants = [...this.state.participants, ...res.data]
                this.setState({ Modalvisible: false, workTitle, tempId: id }, () => {

                    //详情时候需要调/workflow/add,传工单的showid
                    // if (this.state.detailVisible) {
                        this.addWorkflow(this.state.gongshowId, res.data)
                    // }

                })
            }
        })
    }
    handleOk = () => {
        this.setState({ Modalvisible: false })
    }
    handleCancel = () => {
        this.setState({ Modalvisible: false })
    }
    addWorkflow(showId, peopleList) {
        let data = {
            showId,
            tempId: this.state.tempId
        }
        addWorkflow(data).then(res => {
            if (res.status === 0) {
                // if (this.state.detailVisible) {
                    //peopleList : 添加工作流获取的人员列表
                    //需要调修改参与人接口
                    this.modfiyDetail({ flag: 1, userId: peopleList })

                    this.setState({
                        launch: [],
                        participants: [],
                    }, () => {
                        this.getDetail()
                        this.getworkflow()
                    })

                // }
            }
        })
    }
}
