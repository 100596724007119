import React, { Component } from 'react'
import { TextareaItem, Icon, List, Picker, InputItem, ImagePicker, Button, Checkbox, Calendar, Toast, Modal } from 'antd-mobile'
import { getDeatil, getworkflow, getComments, getWorkProcess,getDingDingToken, deleteComment,getDingToken, getpeopleBywork, setComment, GetQueryString, modfiyDetail } from '../../services/index'
import { url , WsApi } from '../../utils/request'
import qianjiantou from '../../assets/images/qianjiantou@3x.png'
import shenjiantou from '../../assets/images/shenjiantou@3x.png'
import shenjiantou_zhong from '../../assets/images/shenjiantou_zhong3x.png'
import dd from 'gdt-jsapi';
// import alert from "gdt-jsapi/alert";
import './detail.scss'
const alert = Modal.alert;

let websocket

export default class Detail extends Component {
    constructor(props) {
        super(props);
        this.state = {
            describtion: '',
            participants: [],
            process: '',
            labelList: "",
            gmtCreate: "",
            downTime: '',
            endTime: '',
            startTime: "",
            commentList: '',//评论list
            text: ""
        }
    }
    componentDidMount() {
        localStorage.setItem('doId',GetQueryString('doId'))
        this.getDetail()
        this.getworkflow()
        this.getComments()
        this.initWebSocket()
    }
    initWebSocket() {
        let that = this
        try {
            if ('WebSocket' in window) {
                websocket = new WebSocket(`${WsApi}/do/comment/${localStorage.getItem('doId')}`)
                websocket.onerror = this.setErrorMessage
                websocket.onopen = this.setOnopenMessage
                websocket.onmessage = this.setOnmessageMessage
                websocket.onclose = this.setOncloseMessage
                window.onbeforeunload = this.onbeforeunload
            } else {
                window.alert('当前浏览器 Not support websocket')
            }
        } catch (e) {
            this.reconnect(url)
            console.log(e)
        }
    }
    setErrorMessage() {

    }
    onbeforeunload() {

    }
    setOnopenMessage() {
        console.log("Connection open ...");
        // websocket.send();
    }
    setOnmessageMessage = (evt) => {
        // console.log( JSON.parse(evt.data) )
        // console.log( this.state.commentList);
        this.state.commentList = evt.data && JSON.parse(evt.data).data.map(item => ({ ...item, play: false }))
        this.setState({})
        // ws.close();
    }
    setOncloseMessage() {
        console.log("Connection closed.");
    }
    checkList = () => {
        if (localStorage.getItem('checks')) {
            //需要保存之前的participants
            let participants = JSON.parse(localStorage.getItem('participants')) //添加参与人之前的参与人
            let checks = JSON.parse(localStorage.getItem('checks')) && JSON.parse(localStorage.getItem('checks')).map(item => ({ userId: item.showId }))
            this.state.participants = [...participants, ...checks]
            this.modfiyDetail({ flag: 1, userId: checks.map(item => item.userId) })
            localStorage.removeItem('checks')
        }
        this.setState({})
    }
    getDetail = () => {
        getDeatil({ showId : localStorage.getItem('doId') }).then(res => {
            if (res.status === 0) {
                this.state.participants = res.data.participantsMapList
                this.state.describtion = res.data.describtion
                this.state.labelList = res.data.labelList
                this.state.gmtCreate = res.data.gmtCreate
                this.state.downTime = res.data.downTime
                this.state.endTime = res.data.endTime
                this.state.startTime = res.data.startTime
                this.setState({}, () => {
                    //是否从choosepeople组建进来，是的话，组合参与人
                    this.checkList()
                })
            }
        })
    }
    getworkflow = () => {
        getworkflow({ showId:localStorage.getItem('doId') }).then(res => {
            if (res.status === 0) {
                console.log(res.data);
                this.setState({ process: res.data })
            } else if (res.status === 4101) {
                this.setState({ process: '' })
            }
        })
    }
    getComments = () => {
        getComments({ showId :localStorage.getItem('doId') }).then(res => {
            if (res.status === 0) {
                this.setState({ commentList: res.data.map(item => ({ ...item, play: false })) })
            }
        })
    }
    changeDescribtion = (describtion) => {
        this.setState({ describtion })
    }
    blueText = (describtion) => {
        console.log(describtion);
        this.modfiyDetail({ describtion: this.state.describtion })
    }
    delImg = (files, type, index) => {
        this.modfiyDetail({ flag: 2, userId: [this.state.participants[index].userId] })
        this.state.participants.splice(index, 1)
        this.setState({})
    }
    addImg = (e) => {
        e.preventDefault();
        localStorage.setItem('participants', JSON.stringify(this.state.participants))
        this.props.history.push(`/choosePeople?doId=${GetQueryString('doId')}`);
    }
    modfiyDetail = ({ describtion, flag, userId }) => {
        let data = {
            showId: GetQueryString('doId'),
            describtion,
            flag,
            userId: userId,
        }
        modfiyDetail(data).then(res => {
            if (res.status === 0) {
                Toast.success(res.description, 1);
            }
        })
    }
    returnlist = () => {
        // if (GetQueryString('isIndex') === '1') {
            // this.props.history.push(`/H5?showId=${GetQueryString('showId')}`);
        // } else {
            this.props.history.push(`/list`);
        // }
    }
    render() {
        const {
            describtion,
            labelList,
            process,
            participants,
            gmtCreate,
            downTime,
            endTime,
            startTime,
            commentList,
            text
        } = this.state
        let files = participants.map((item, index) => {
            return {
                id: index,
                userId: item.userId,
                url: `${url}/yidoBase/avatar?showId=${item.userId}`
            }
        })
        return (
            <div className='h5detail'>
                <div style={{ height: 'calc(100vh - 50px)', overflowY: 'auto' }}>
                    <p className='returnIcon'><i className="iconfont icon-ffanhui-" onClick={this.returnlist}></i></p>
                    <TextareaItem
                        ref={el => this.autoFocusInst = el}
                        placeholder="请输入内容"
                        className='textarea'
                        value={describtion}
                        labelNumber={5}
                        rows={5}
                        count={500}
                        onChange={this.changeDescribtion}
                        onBlur={this.blueText}
                    />
                    <div style={{
                            background:' #fff',
                            margin:'10px' ,
                            borderRadius: '10px'
                    }}>
                        <div className='tags'>
                            <i className="iconfont icon-tag"></i>
                            {labelList && labelList.map(item => item.labelName).join('，')}
                        </div>
                        <div className="process">
                            <p>{process && `流程：${process.entity.tempName}`}</p>
                            <div className='processContainer'>
                                {process ? process.nodes.map((item, index) => {
                                    if (item.clazz === 'endGreen') {
                                        // return <span key={index} className='finishspan'>结束</span>
                                    return <span key={index} className='finishspan'>
                                                <img src={qianjiantou} ></img>
                                                <span className='over'>结束</span>
                                        </span>
                                    } else {
                                        return <span key={index} className='content'>
                                            {item.approvalUser.length > 0 && item.approvalUser.map((v, i) => {
                                                // return <span key={i} >
                                                //     <img src={`${url}/yidoBase/avatar?showId=${v.showId}`} />
                                                //     <i className="iconfont icon-jiantou1"></i>
                                                //     <span className='trip'>{this.getStatus(item)}</span>
                                                // </span>
                                                console.log(v);
                                                return <span key={i}>
                                                    {item.clazz ==='start' && <img src={shenjiantou} className='backG' ></img>}
                                                    {item.clazz !=='start' && <img src={shenjiantou_zhong} className='backG' ></img>}
                                                    <img className='user'  src={`${url}/yidoBase/avatar?showId=${v.showId}`}  />
                                                    <span  className='trip'>{this.getStatus(item)}</span>
                                                </span>
                                            })}
                                        </span>
                                    }
                                }) : ''}
                            </div>
                        </div>
                        <div className='time'>
                            <span>创建时间： {gmtCreate}</span>
                            <span>计划时间：{startTime ? `${startTime.split(' ')[0]} ~ ${endTime.split(' ')[0]}` : "无"}</span>
                            <span>办结时间：{downTime || '未办结'}</span>
                            <span></span>
                        </div>
                    </div>
                    <div className='participants'>
                        <i className="iconfont icon-yonghu-fuben"/>
                        <ImagePicker
                            length={8}
                            files={files}
                            onChange={this.delImg}
                            onAddImageClick={this.addImg}
                        />
                    </div>
                    {/* <div className='time'>
                        <span>创建时间： {gmtCreate}</span>
                        <span>计划时间：{startTime ? `${startTime.split(' ')[0]} ~ ${endTime.split(' ')[0]}` : "无"}</span>
                        <span>办结时间：{downTime || '未办结'}</span>
                        <span></span>
                    </div> */}
                    <div>
                      {/* <Button onClick={this.addChildOnedo}  type="primary"  >新建下级1do</Button> */}
                    </div>
                    {commentList.length>0 &&<div className='comment'>
                        <div className='title'>评论</div>
                        {commentList && commentList.map((item,index) => {
                            return <div key={item.id} className={`commentList ${index ===commentList.length-1 ? 'ending':''}`}>
                                <span className='name'>{item.userName}</span>
                                <span className='time'>{item.gmtCreate}</span>
                                {item.type === 1 && <span className='content'>{item.comment}</span>}
                                {item.type === 3 && <span className='content'>
                                    <i
                                        className={item.play ? `iconfont  icon-bofangzhong` : `iconfont  icon-ziyuan`}
                                        onClick={() => { this.play(item) }}
                                    ></i><span>{JSON.parse(item.comment).duration}’’</span>
                                </span>}
                                {item.userShowId === localStorage.getItem('showId') &&<span className='action' onClick={() => { this.deleteComment(item) }}>删除</span>}
                            </div>
                        })}
                    </div>}
                </div>
                <div className="news">
                    <i className="iconfont icon-yuyin1" onClick={this.startRecordAudio}></i>
                    <InputItem
                        className='InputItem'
                        clear
                        placeholder="请输入消息"
                        onChange={this.changeText}
                        ref={el => this.autoFocusInst = el}
                        value={text}
                    ></InputItem>
                    <Button onClick={this.send} className='button' type="primary" inline >发送</Button>
                    {/* <button style={{ marginLeft: '30px' }} onClick={this.stop}>停止</button> */}
                </div>
            </div>
        )
    }
    addChildOnedo=()=>{
        this.props.history.push(`/appAdd`);
    }
    send = () => {
        // alert(this.state.text)
        console.log(this.state.text);
        this.setState({ text: "" })

        this.setComment({
            type: 1,
            comment: this.state.text
        })
    }
    changeText = (text) => {
        //   console.log(text);
        this.setState({ text })
    }
    play = (item) => {
        // this.getDingToken()
        if (!item.play) {//去播放
            dd.downloadAudio({
                mediaId:JSON.parse(item.comment).mediaId
            }).then(r => {
                console.log(r);
                // window.alert(r.localAudioId)
                dd.playAudio({
                    localAudioId: r.localAudioId,
                }).then(res => {
                    console.log(res)
                    // window.alert('停止')

                }).catch(err => { })
                //鉴权
                this.getDingToken()
                //监听播放自动停止。
                dd.onAudioPlayEnd().then(v => {
                    item.play = false
                    this.setState({})
                }).catch(err => {
                    console.error(err);
                })
            }).catch(err => {
            })
        }
        item.play = true
        this.setState({})
        console.log(item);
    }
    getDingToken=()=>{
        getDingToken().then(res=>{
        //   window.alert(JSON.stringify(res) );
          if(res.status === 0){
            //   window.alert(res.data.accessToken)
              dd.authConfig({
                ticket: res.data.accessToken,
                jsApiList:["alert","onAudioPlayEnd"]
            }).then(res =>{
                // window.alert(JSON.stringify(res))
            }).
            catch(err =>{

            })
          }
        })
    }
    deleteComment = ({ id }) => {
        deleteComment({ id }).then(res => {
            if (res.status === 0) {
                Toast.success(res.description, 1);
            }
        })
    }
    getStatus = ({ clazz, nodeStatus }) => {
        if (clazz === 'start') {
            return '发起'
        } else if (clazz === 'userTask') {
            if (nodeStatus === 0) {
                return '待审批'
            } else if (nodeStatus === 1) {
                return '进行中'
            } else if (nodeStatus === 2) {
                return '已完成'
            }
        }
    }
    startRecordAudio = () => {
        dd.startRecordAudio().then(res => {
            console.log(res)
            // window.alert(res)
        }).catch(err => {
            // window.alert(err)
        })
        alert('请语音', '', [
            { text: '取消', onPress: () => this.stopRecordAudio() },
            { text: '语音完成', onPress: () => this.stop() },
        ])

    }
    stopRecordAudio = () => {
        dd.stopRecordAudio().then(res => {
            Toast.info('已取消', 1);
        }).catch(err => { })
    }
    stop = () => {
        dd.stopRecordAudio().then(res => {
            // console.log(res)
            // window.alert(res.mediaId)
            this.setComment({ type: 3, comment:JSON.stringify(res)})
            dd.downloadAudio({
                mediaId: res.mediaId
            }).then(r => {
                console.log(r);
                // window.alert(r.localAudioId)

                // dd.playAudio({
                //     localAudioId: r.localAudioId,
                // }).then(res => {
                //     console.log(res)
                // }).catch(err => { })
            }).catch(err => {
            })

        }).catch(err => {
            window.alert(err)
        })
    }
    setComment({ type, comment }) {
        let data = {
            type,
            comment,
            userShowId: localStorage.getItem('showId'),
            showId: GetQueryString('doId'),
        }
        setComment(data).then(res => {
            //  window.alert(res.status)
            if (res.status === 0) {
                Toast.success(res.description, 1);
            } else {
                Toast.fail(res.description, 1);
            }
        })
    }
}
