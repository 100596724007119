import React, { Component } from 'react'
import { Tabs, ListView, Icon, Toast } from 'antd-mobile'
import { getOnedoList, GetQueryString } from '../../services/index'
import './listDetail.scss'
const tabs = [
    { title: '全部', tab: 0 },
    { title: '未读', tab: 4 },
    { title: '待接单', tab: 1 },
    { title: '已接单', tab: 2 },
    { title: '催单   ', tab: 5 },
];
export default class ListDetail extends Component {
    constructor(props) {
        super(props);
        const dataSource = new ListView.DataSource({
            rowHasChanged: (row1, row2) => row1 !== row2,
        });
        this.state = {
            dataSource,
            isLoading: true,
            data: [],
            pageNum: 1,
            pageSize: 20,
            dataBlob: [],
            isLastPage: false,
            tab: 0,//页签：0.全部 1.待接单 2.已接单 3.已完成 4.未读 5.催单
        };
    }
    componentDidMount() {
        !localStorage.getItem('token') && this.setLocal()
        this.getData()
    }
    setLocal=()=>{
        localStorage.setItem('showId',GetQueryString('showId'))
        localStorage.setItem('token',GetQueryString('token'))
        localStorage.setItem('sourceEventId',GetQueryString('sourceEventId'))
        localStorage.setItem('sourceId',GetQueryString('sourceId'))
    }
    getData = () => {
        const { pageNum, pageSize, tab, isLastPage, data, dataBlob } = this.state
        if (isLastPage) {
            this.setState({
                isLoading: false,
            })
            Toast.info('没有数据啦！', 1);
            return;
        }
        let body = {
            userId: GetQueryString('showId'),
            pageNum,
            pageSize,
            tab,
        }
        getOnedoList(body).then(res => {
            if (res.status === 0) {
                if (res.data.isLastPage) {
                    this.setState({
                        isLastPage: true,
                    })
                }
                const lg = res.data.list.length;
                for (let i = 0; i < lg; i++) {
                    dataBlob.push(`row - ${(pageNum * lg) + i}`);
                    data.push(res.data.list[i])
                }
                this.setState({
                    dataSource: this.state.dataSource.cloneWithRows(dataBlob),
                    isLoading: false,
                })
            }
        })
    }
    add = () => {
        localStorage.removeItem('doId')
        this.props.history.push(`/appAdd`);
    }
    onEndReached = (event) => {
        // load new data
        // hasMore: from backend data, indicates whether it is the last page, here is false
        if (this.state.isLoading && !this.state.hasMore) {
            return;
        }
        // console.log('reach end', event);
        this.setState({ isLoading: true, pageNum: this.state.pageNum + 1 }, () => {
            this.getData()
        })
    }
    getStatus = ({ status, isRead,isUrging }) => {
        //status： 1待接单  2已接单  3已完成 
        // isRead  false 未读 , true 显示status
        //isUrging true 催办
        if(isUrging && status!=3){
            return '催办'
        }
        if (!isRead) {
            return '未读'
        } else {
            if (status === 1) {
                return '待接单'
            } else if (status === 2) {
                return '已接单'
            } else if (status === 3) {
                return '已完成 '
            }
        }
    }
    getClassName=({ status, isRead ,isUrging})=>{
        if(isUrging && status!=3){
            return 'cui'
        }
        if (!isRead) {
            return 'weidu'
        } else {
            if (status === 1) {
                return 'dai'
            } else if (status === 2) {
                return 'yi'
            } else if (status === 3) {
                return 'finish '
            }
        }
    }
    changeTabs = ({ tab }, index) => {
        console.log(tab);
        this.setState({ tab, 
            pageNum: 1, 
            isLoading: true,
            data: [], 
            dataBlob: [],
            isLastPage: false,
            dataSource : new ListView.DataSource({
                rowHasChanged: (row1, row2) => row1 !== row2,
            })
         }, () => {
            this.getData()
        })
    }
    showDetail=(row)=>{
      console.log(row);
      this.props.history.push(`/appDetail?doId=${row.showId}`);
    }
    render() {
        const separator = (sectionID, rowID) => (
            <div
                key={`${sectionID}-${rowID}`}
                style={{
                    height: 2,
                    borderBottom: '1px dashed #ECECED',
                }}
            />
        );
        const row = (rowData, sectionID, rowID) => {
            let obj = this.state.data[rowID]
            return (
                <div className='rows' key={rowID} >
                    <span className='status'>
                        <span className={this.getClassName(obj)}>{this.getStatus(obj)}</span>
                    </span>
                    <span className='describtion'>{obj.describtion}</span>
                    <span className='name'>{obj.sponsorList.join(',')}</span>
                    <span className="icon">
                        <i className="iconfont icon-jiantou" onClick={() => { this.showDetail(this.state.data[rowID]) }}></i>
                    </span>
                </div>
            );
        };
        return (
            <div className='tabsContainer'>
                <i className="iconfont icon-icon-test" onClick={this.add}></i>
                <Tabs tabs={tabs}
                    initialPage={0}
                    className='tabs'
                    onChange={(tab, index) => { this.changeTabs(tab, index) }}
                    onTabClick={(tab, index) => { console.log('onTabClick', index, tab); }}
                >
                    <div className='tabContent'>
                        <ListView
                            ref={el => this.lv = el}
                            dataSource={this.state.dataSource}
                            renderFooter={() => (<div style={{ padding: 1, textAlign: 'center' }}>
                                {this.state.isLoading ? '加载中' : '没有数据啦'}
                            </div>)}
                            renderRow={row}
                            renderSeparator={separator}
                            className="am-list"
                            pageSize={4}
                            initialListSize={20}
                            useBodyScroll
                            onScroll={() => { console.log('scroll'); }}
                            onEndReached={this.onEndReached}
                            onEndReachedThreshold={10}
                            scrollRenderAheadDistance={500}
                        />
                    </div>
                </Tabs>
            </div>
        )
    }
}
