import React, { Component } from 'react'
import { ListView, Icon, Toast } from 'antd-mobile'
import { getOnedoList, GetQueryString } from '../../services/index'
import 'antd-mobile/dist/antd-mobile.css';  // or 'antd-mobile/dist/antd-mobile.less'
import './index.scss'

export default class H5 extends Component {
    constructor(props) {
        super(props);
        const dataSource = new ListView.DataSource({
            rowHasChanged: (row1, row2) => row1 !== row2,
        });
        this.state = {
            dataSource,
            isLoading: true,
            data: [],
            pageNum: 1,
            pageSize: 20,
            dataBlob: [],
            isLastPage: false
        };
    }
    componentDidMount() {
        // you can scroll to the specified position
        // setTimeout(() => this.lv.scrollTo(0, 120), 800);

        // simulate initial Ajax
        this.getData()
        localStorage.setItem('user',GetQueryString('showId'))
        localStorage.setItem('token',GetQueryString('token'))
    }
    getData = () => {
        const { pageNum, pageSize, dataBlob, data, isLastPage } = this.state
        if (isLastPage) {
            this.setState({
                isLoading: false,
            })
            Toast.info('没有数据啦！', 1);
            return;
        }
        let body = {
            userId: GetQueryString('showId'),
            pageNum,
            pageSize,
            tab: 0
        }
        // console.log(a);
        getOnedoList(body).then(res => {
            if (res.status === 0) {
                if (res.data.isLastPage) {
                    this.setState({
                        isLastPage: true,
                    })
                }
                const lg = res.data.list.length;
                for (let i = 0; i < lg; i++) {
                    dataBlob.push(`row - ${(pageNum * lg) + i}`);
                    data.push(res.data.list[i])
                }
                this.setState({
                    dataSource: this.state.dataSource.cloneWithRows(dataBlob),
                    isLoading: false,
                })
            }
        })
    }
    onEndReached = (event) => {
        // load new data
        // hasMore: from backend data, indicates whether it is the last page, here is false
        if (this.state.isLoading && !this.state.hasMore) {
            return;
        }
        // console.log('reach end', event);
        this.setState({ isLoading: true, pageNum: this.state.pageNum + 1 }, () => {
            this.getData()
        })
    }
    showDetail = (row) => {
        console.log(row);
        this.props.history.push(`/appDetail?showId=${GetQueryString('showId')}&doId=${row.showId}&isIndex=1`);
    }
    getStatus = ({ status, isUrging }) => {
        //status： 1待接单  2已接单  3已完成 
        //isUrging： status 1 2时候，看isUrging，true催办，false时取status
        if (status === 1) {
            if (isUrging) {
                return '催办'
            } else {
                return '待接单'
            }
        } else if (status === 2) {
            if (isUrging) {
                return '催办'
            } else {
                return '已接单'
            }
        } else if (status === 3) {
            return '已完成 '
        }

    }
    getHeader = () => {
        return <div className='menu'>
            <span>1do</span>
            <Icon type="ellipsis" onClick={this.showListDetail} />
        </div>
    }
    showListDetail = () => {
        this.props.history.push(`/list?showId=${GetQueryString('showId')}`);
    }
    render() {
        const separator = (sectionID, rowID) => (
            <div
                key={`${sectionID}-${rowID}`}
                style={{
                    height: 2,
                    borderBottom: '1px dashed #ECECED',
                }}
            />
        );
        const row = (rowData, sectionID, rowID) => {
            let obj = this.state.data[rowID]
            return (
                <div className='rows' key={rowID} >
                    <span className='status'>{this.getStatus(obj)}</span>
                    <span className='describtion'>{obj.describtion}</span>
                    <span className="icon">
                        <i className="iconfont icon-caidan" onClick={() => { this.showDetail(this.state.data[rowID]) }}></i>
                    </span>
                </div>
            );
        };
        return (
            <div className='h5container'>
                <ListView
                    ref={el => this.lv = el}
                    dataSource={this.state.dataSource}
                    renderHeader={this.getHeader}
                    renderFooter={() => (<div style={{ padding: 1, textAlign: 'center' }}>
                        {this.state.isLoading ? '加载中' : '没有数据啦'}
                    </div>)}
                    renderRow={row}
                    renderSeparator={separator}
                    className="am-list"
                    pageSize={4}
                    initialListSize={20}
                    useBodyScroll
                    onScroll={() => { console.log('scroll'); }}
                    onEndReached={this.onEndReached}
                    onEndReachedThreshold={10}
                    scrollRenderAheadDistance={500}
                />
            </div>
        )
    }
}
