import React, { Component } from 'react'
import { ImagePicker, Button, Checkbox, InputItem } from 'antd-mobile'
import { getWorkProcess, getpeopleBywork, GetQueryString, regUserList } from '../../services/index'
import { url } from '../../utils/request'
import './choosePeople.scss'
const CheckboxItem = Checkbox.CheckboxItem;

export default class ChoosePeople extends Component {
    constructor(props) {
        super(props);
        this.state = {
            peopleList: [],//人员列表,
            word: '',
            checkList: []
        };
    }
    componentDidMount() {
        this.regUserList()
    }
    regUserList = () => {
        let data = {
            pageNum: 1,
            pageSize: 10,
            word: this.state.word
        }
        regUserList(data).then(res => {
            if (res.status === 0) {
                let allList = res.data.list
                let checks = this.state.checkList
                for (let i = 0; i < allList.length; i++) {
                    if (checks.map(item => item.showId).indexOf(allList[i].showId) == -1) {
                        allList[i].disabled = false
                    } else {
                        allList[i].disabled = true
                    }
                }
                console.log(allList);
                this.setState({ peopleList: allList })
            }
        })
    }
    onChangePeople = (item) => {
        if(!item.disabled){
            item.disabled = true  //修改people的disabled
            this.state.checkList.push(item)
        }else{
            item.disabled = false
            //删除checklist中的对应元素
            this.deleteCheckList(item.showId)
        }
        this.setState({})
    }
    deleteCheckList=(showId)=>{
         let checks =   this.state.checkList
            for (let i = 0; i < checks.length; i++) {
                if(checks[i].showId === showId){
                    this.state.checkList.splice(i,1)
                }
            }
    }
    deletepeople=(item)=>{
        console.log(item);
        item.disabled = false
        //删除checklist中的对应元素
        this.deleteCheckList(item.showId)
        //修改people的disabled
        let peoples = this.state.peopleList
        for (let i = 0; i < peoples.length; i++) {
            if(peoples[i].showId === item.showId){
                peoples[i].disabled = false
            }
        }
        this.setState({})
    }
    changeWord = (word) => {
        this.setState({ word })
    }
    select = () => {
        this.regUserList()
    }
    delImg = (files, type, index) => {
        this.state.checkList.splice(index, 1)
        this.setState({})
    }
    submit=()=>{
        let checks = JSON.stringify(this.state.checkList)
        localStorage.setItem('checks',checks)
        if(GetQueryString('doId')){
            this.props.history.push(`/appDetail?doId=${GetQueryString('doId')}`);
            return
        }
        this.props.history.push(`/appAdd`);
    }
    render() {
        const { peopleList, word, checkList } = this.state
        return (
            <div className='choosePeopleH5'>
                <InputItem
                    clear
                    value={word}
                    onChange={this.changeWord}
                    placeholder="关键字查询"
                >输入：</InputItem>
                <Button className='select' onClick={this.select}>查询</Button>
                <div className='list'>
                    {peopleList.length > 0 && peopleList.map(item => {
                        return <CheckboxItem
                            key={item.showId}
                            checked={item.disabled}
                            onChange={() => this.onChangePeople(item)}>
                            <img src={`${url}/yidoBase/avatar?showId=${item.showId}`} />{item.name}
                        </CheckboxItem>
                    })}
                </div>
                <div className='selectList'>
                    已选择：<br />
                    {checkList && checkList.map(item => {
                        // if (item.disabled) {
                            return <div key={item.showId} className='selectRow'>
                                <span>{item.name}</span>
                                <i className="iconfont icon-shanchu1" onClick={()=>{this.deletepeople(item)}}></i>
                            </div>
                        // } 
                    })}
                </div>
                <Button type="primary"onClick={this.submit} >确定</Button>
            </div>
        )
    }
}
