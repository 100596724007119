import React, { Component } from 'react'
import {  Button,  Modal,  } from 'antd';

export default class WorkFlow extends Component {
    render() {
        const { Modalvisible, workList, addWork, handleCancel, handleOk } = this.props
        return (
            <div>
                <Modal
                    title="工作流列表"
                    visible={Modalvisible}
                    onOk={handleOk}
                    className='worklist'
                    footer={null}
                    onCancel={handleCancel}
                >
                    <ul>
                        {workList && workList.map(item => {
                            return <li key={item.id}>{item.workflowName}
                                <Button className='addbutton' type="link" onClick={() => { addWork(item.id, item.workflowName) }}>添加</Button>
                            </li>
                        })}
                    </ul>
                </Modal>
            </div>
        )
    }
}
