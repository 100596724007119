import React, { Component } from 'react'
import { Input, Table, Icon,Tooltip, Button, message, Checkbox, Drawer, Select, DatePicker, Modal, TimePicker, } from 'antd';
import './index.scss'
import moment from 'moment';
import OnedoDetail from '../../components/onedoDetail'
import OnedoAdd from '../../components/onedoAdd'
import WorkFlow from '../../components/workFlow'
import Choosepeople from '../../components/choosepeople'
import { getWorkProcess, getpeopleBywork, GetQueryString, getOnedoList, regUserList, getworkflow, addOnedo, sourceList, addWorkflow, getDeatil, modfiyDetail, updateStatus } from '../../services/index'
const { Option } = Select;
const { RangePicker } = DatePicker;
const { confirm } = Modal;
// ceshi

export default class index extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showId: '',//详情需要
            current: 1,
            keyword: '',
            faqiPeople: "",
            canyuPeople: '',
            createTimeStart: null,
            createTimeEnd: null,
            finishStart: null,
            finishEnd: null,
            sourceId: '',
            sourceList: '',//来源系统
            process: '',
            tempId: '',
            eventType: '',
            eventTypeList: [],
            total: 0,
            show: false,
            visible: false,
            detailVisible: false,
            Modalvisible: false,
            peoplevisible: false,//人员
            launch: [],//发起人
            describtion: '',
            participants: [],//参与人
            workList: '',
            fileList: [],//新建文件列表
            workTitle: '',//工作流标题
            checkAll: false,
            checkedList: [],
            plainOptions: [],
            indeterminate: true,
            datacreate: "",
            timecreate: "",
            planTimestart: null,
            planTimeend: null,
            detailData: "",
            columns: [{
                title: '系统',
                dataIndex: 'sourceName',
                key: 'sourceName',
            }, {
                title: '内容',
                dataIndex: 'describtion',
                key: 'describtion',
                render: (text, record) => {
                    return <Tooltip placement="right" title={text}>
                         <span className="canyuPeople">
                             {text}
                         </span>
                        </Tooltip>
                }
            }, {
                title: '创建时间',
                dataIndex: 'gmtCreate',
                key: 'gmtCreate',
            }, {
                title: '参与人',
                dataIndex: 'participantsList',
                key: 'participantsList',
                width: '30%',
                render: (text, record) => {
                    return <Tooltip placement="right" title={text && text.join(',')}>
                        <span className="canyuPeople">
                            {text && text.join(',')}
                        </span>
                    </Tooltip>
                }
            }, {
                title: '计划时间',
                dataIndex: 'planTime',
                key: 'planTime',
                render: (text, record) => {
                    return <span>
                        {record.startTime ? `${record.startTime} 至 ${record.endTime}` : '无'}
                    </span>
                }
            }, {
                title: '事件类型',
                dataIndex: 'sourceEventName',
                key: 'sourceEventName',
            }, {
                title: '操作',
                key: 'action',
                render: (text, record) => (
                    <span>
                        <Button onClick={(e) => { this.deteleOndedo(record, e) }}>删除</Button>
                    </span>
                ),
            }],
            data: []
        };
    }
    componentDidMount() {
        this.setImgInit()
        this.sourceList()
        this.getOnedoList()
    }
    setImgInit() {
        let showId = GetQueryString('showId')
        localStorage.setItem('showId', showId)
        localStorage.setItem('token',GetQueryString('token'))
        localStorage.setItem('sourceEventId',GetQueryString('sourceEventId'))
        localStorage.setItem('sourceId',GetQueryString('sourceId'))
        this.state.launch.push({ showId })
        this.state.participants.push({ showId })
        this.setState({})
    }
    sourceList = () => {
        sourceList({}).then(res => {
            if (res.status === 0) {
                this.setState({ sourceList: res.data })
            }
        })
    }
    getOnedoList = () => {
        const { keyword, current, faqiPeople, sourceId, eventType, canyuPeople, createTimeStart, createTimeEnd, finishStart, finishEnd } = this.state
        let data = {
            creatTime: createTimeStart,
            describtion: keyword,
            downTime: createTimeEnd,
            endTime: finishEnd,
            pageNum: current,
            pageSize: 10,
            participants: canyuPeople,
            sourceEventId: eventType,
            sourceId,
            sponsor: faqiPeople,
            startTime: finishStart
        }
        getOnedoList(data).then(res => {
            if (res.status === 0) {
                this.setState({
                    data: res.data.list.map((item, index) => ({ ...item, key: index })),
                    total: res.data.total,
                })
            }
        })
    }
    deteleOndedo = ({ showId }, e) => {
        e.stopPropagation()
        let that = this
        confirm({
            title: '是否要删除?',
            onOk() {
                let data = {
                    showId,
                    status: 2
                }
                updateStatus(data).then(res => {
                    if (res.status === 0) {
                        message.success(res.description)
                        that.getOnedoList()
                    }
                })
            },
            onCancel() {
            },
        });
    }
    show = () => {
        this.setState({
            show: !this.state.show
        })
    }
    handleChange = (value) => {
        console.log(value);
        this.setState({ sourceId: value, eventType: '' }, () => {
            this.getOnedoList()
        })
    }
    handleChange1 = (value) => {
        this.setState({ eventType: value }, () => {
            this.getOnedoList()
        })
    }
    add = () => {
        this.state.participants = []
        this.state.launch = []
        this.state.tempId = ''
        this.state.showId = ''
        this.setImgInit()
        this.setState({ visible: true, workTitle: "" })
    }
    onClose = (key) => {
        console.log(key);
        this.setState({ visible: false })
    }
    submit = (describtion, fileList, planTimestart, planTimeend) => {
        console.log(this.state.showId);
        if (!describtion) {
            message.warning('内容不能为空！')
            return;
        }
        if (this.state.participants.length <= 0) {
            message.warning('参与人不能为空！')
            return;
        }
        const { launch, participants } = this.state
        let data = {
            createUserShowId: launch[0].showId,
            describtion,
            title: describtion,
            endTime: planTimeend,
            startTime: planTimestart,
            file: fileList,
            participants: participants.map(item => ({ userShowId: item.showId })),
            sponsor: launch.map(item => ({ userShowId: item.showId })),
            sourceEventId: localStorage.getItem('sourceEventId'),
            sourceId: localStorage.getItem('sourceId'),
        }
        this.state.showId && (data.parentId = this.state.showId)
        addOnedo(data).then(res => {
            if (res.status === 0) {
                message.success('添加1do成功');
                this.setState({ visible: false })
                this.state.tempId && this.addWorkflow(res.data)
                this.getOnedoList()
            }
        })
    }
    //详情中添加工作流有 peopleList
    addWorkflow(showId, peopleList) {
        let data = {
            showId,
            tempId: this.state.tempId
        }
        addWorkflow(data).then(res => {
            if (res.status === 0) {
                if (this.state.detailVisible) {
                    //peopleList : 添加工作流获取的人员列表
                    //需要调修改参与人接口
                    this.modfiyDetail({ flag: 1, userId: peopleList })

                    this.setState({
                        launch: [],
                        participants: [],
                    }, () => {
                        this.getDetail(this.state.showId)
                        this.getworkflow(this.state.showId)
                    })

                }
            }
        })
    }
    addprocess = () => {
        this.setState({ Modalvisible: true })
        this.getWorkProcess()
    }
    getWorkProcess() {
        let data = {
            pageNum: 1,
            pageSize: 20,
            workflowStatus: 0
        }
        getWorkProcess(data).then(res => {
            if (res.status === 0) {
                this.setState({ workList: res.data.list })
            }
        })
    }
    handleOk = () => {
        this.setState({ Modalvisible: false })
    }
    handleCancel = () => {
        this.setState({ Modalvisible: false })
    }
    addWork = (id, workTitle) => {
        getpeopleBywork({ id }).then(res => {
            if (res.status === 0) {
                this.state.participants = [...this.state.participants, ...res.data]
                this.setState({ Modalvisible: false, workTitle, tempId: id }, () => {

                    //详情时候需要调/workflow/add,传工单的showid
                    if (this.state.detailVisible) {
                        this.addWorkflow(this.state.showId, res.data)
                    }

                })
            }
        })
    }
    addImg = () => {
        this.setState({ peoplevisible: true, checkedList: [], })
        this.getpeople()
    }
    getpeople = (word = '') => {
        let data = {
            "pageNum": 1,
            "pageSize": 100,
            word
        }
        regUserList(data).then(res => {
            if (res.status === 0) {
                this.setState({
                    plainOptions: res.data.list.map(item => ({ label: item.name, value: item.showId }))
                })
            }
        })
    }
    reduceImg = () => {
        this.state.participants = this.state.participants.map(item => {
            return { ...item, show: !item.show }
        })
        console.log(this.state.participants)
        this.setState({})
    }
    reduceIndex = (index) => {
        console.log(this.state.participants[index]);
        //详情里删除人员，
        if (this.state.detailVisible) {
            this.modfiyDetail({ flag: 2, userId: [this.state.participants[index]] })
        }
        // ------------
        this.state.participants.splice(index, 1)
        console.log(this.state.participants);
        this.setState({})
    }
    handleOkPeoplevisible = () => {
        let peoples = this.state.checkedList.map(item => ({ showId: item }))
        this.state.participants = this.unique([... this.state.participants, ...peoples]) //需要对participants去重
        this.setState({ peoplevisible: false })
        // ------------
        //详情里添加人员，只传递了新增的人员
        if (this.state.detailVisible) {
            this.modfiyDetail({ flag: 1, userId: peoples })
        }
    }
    unique = (arr) => {
        var array = [];
        for (var i = 0; i < arr.length; i++) {
            if (array.length > 0) {
                if (array.map(item => item.showId).indexOf(arr[i].showId) === -1) {
                    array.push(arr[i])
                }
            } else {
                array.push(arr[0])
            }
        }
        return array;
    }
    handleCancelPeoplevisible = () => {
        this.setState({ peoplevisible: false })
    }
    onChangeInput = (key, e) => {
        this.state[key] = e.target.value
        this.setState({})
        if (this.interal) {
            clearTimeout(this.interal)
        }
        this.interal = setTimeout(() => {
            this.getOnedoList()
        }, 500)
    }
    changeTime = (key, dates, dateStrings) => {
        if (key === 1) {
            this.setState({
                createTimeStart: dateStrings[0],
                createTimeEnd: dateStrings[1],
            }, () => {
                this.getOnedoList()
            })
        } else if (key === 2) {
            this.setState({
                finishStart: dateStrings[0],
                finishEnd: dateStrings[1],
            }, () => {
                this.getOnedoList()
            })
        }

    }
    handleChangeFile = info => {
        if (info.file.status !== 'uploading') {
            this.setState({ fileList: info.fileList.map(item => ({ annexUrl: item.response.data, annexName: item.name })) })
        }

    }
    onChangepeople = (e) => {
        if (this.interal) {
            clearTimeout(this.interal)
        }
        this.interal = setTimeout(() => {
            this.getpeople(e.target.value)
        }, 500)
    }
    showDetail = (record) => {
        console.log(record);
        this.setState({
            detailVisible: true,
            //  showId: '43d0b2e2-670e-43a3-b85b-71b548010ace',
            showId: record.showId,
            launch: [],
            participants: [],
        }, () => {
            this.getDetail(this.state.showId)
            this.getworkflow(this.state.showId)
        })
    }
    getDetail = (showId) => {
        getDeatil({ showId }).then(res => {
            if (res.status === 0) {
                this.state.detailData = res.data
                this.state.launch = [...this.state.launch, ...res.data.sponsorMapList.map(item => ({ showId: item.userId }))]
                this.state.participants = [... this.state.participants, ...res.data.participantsMapList.map(item => ({ showId: item.userId }))]
                this.setState({})
            }
        })
    }
    getworkflow = (showId) => {
        getworkflow({ showId }).then(res => {
            if (res.status === 0) {
                console.log(res.data);
                this.setState({ process: res.data })
            } else if (res.status === 4101) {
                this.setState({ process: '' })
            }
        })
    }
    render() {
        const { detailData, total, visible, process, showId, workTitle, sourceList, tempId, sourceId, detailVisible, describtion, planTimestart, launch, planTimeend, current, source, eventTypeList, eventType, keyword, faqiPeople, canyuPeople, createTimeStart, createTimeEnd, finishStart, finishEnd, show, columns, data, Modalvisible, workList, participants, peoplevisible } = this.state
        return (
            <div className='home'>
                <p className='title1'>1do看板</p>
                <div className='search'>
                    <Input placeholder="请输入内容" allowClear value={keyword} onChange={(e) => { this.onChangeInput('keyword', e) }} style={{ width: '20%' }} />
                    <span className='more' onClick={this.show}>更多筛选条件</span>
                    <Button type="primary" className='add' onClick={this.add}>新建1do</Button>
                    {show && <div className='moreContent'>
                        <div className='col'>
                            <span className='coltitle'>发起人</span>
                            <Input placeholder="请输入内容" allowClear value={faqiPeople} onChange={(e) => { this.onChangeInput('faqiPeople', e) }} style={{ width: '67%' }} />
                        </div>
                        <div className='col'>
                            <span className='coltitle'>参与人</span>
                            <Input placeholder="请输入内容" allowClear value={canyuPeople} onChange={(e) => { this.onChangeInput('canyuPeople', e) }} style={{ width: '67%' }} />
                        </div>
                        <div className='col'>
                            <span className='coltitle'>来源系统</span>
                            <Select value={sourceId} style={{ width: '67%' }} allowClear onChange={this.handleChange}>
                                {sourceList && sourceList.map(item => {
                                    return <Option key={item.sourceId} value={item.sourceId}>{item.sourceName}</Option>
                                })}
                            </Select>
                        </div>
                        <div className='col'>
                            <span className='coltitle'>创建时间</span>
                            <RangePicker
                                allowClear
                                value={[createTimeStart ? moment(createTimeStart) : null, createTimeEnd ? moment(createTimeEnd) : null]}
                                onChange={(dates, dateStrings) => { this.changeTime(1, dates, dateStrings) }}
                            />
                        </div>
                        <div className='col'>
                            <span className='coltitle'>拟完成时间</span>
                            <RangePicker
                                allowClear
                                value={[finishStart ? moment(finishStart) : null, finishEnd ? moment(finishEnd) : null]}
                                onChange={(dates, dateStrings) => { this.changeTime(2, dates, dateStrings) }}
                            />
                        </div>
                        <div className='col'>
                            <span className='coltitle'>事件类型</span>
                            <Select value={eventType} allowClear style={{ width: '67%' }} onChange={this.handleChange1}>
                                {sourceId && sourceList.filter(item => item.sourceId === sourceId)[0].events.map(item => {
                                    return <Option key={item.sourceEventId} value={item.sourceEventId}>{item.sourceEventName}</Option>
                                })}
                            </Select>
                        </div>
                    </div>
                    }
                </div>
                <div className='result'>
                    <Table
                        columns={columns}
                        className='table'
                        dataSource={data}
                        onRow={record => {
                            return {
                                onClick: event => { this.showDetail(record) }, // 点击行
                            };
                        }}
                        pagination={{
                            showQuickJumper: true,
                            total,
                            current,
                            onChange: this.changePage

                        }}
                    />
                </div>
                {visible && <OnedoAdd
                    visible={visible}
                    launch={launch}
                    participants={participants}
                    workTitle={workTitle}
                    addprocess={this.addprocess}
                    reduceIndex={this.reduceIndex}
                    addImg={this.addImg}
                    reduceImg={this.reduceImg}
                    onClose={this.onClose}
                    submit={this.submit}
                />
                }
                {Modalvisible && <WorkFlow
                    Modalvisible={Modalvisible}
                    workList={workList}
                    addWork={this.addWork}
                    handleOk={this.handleOk}
                    handleCancel={this.handleCancel}
                />}
                {peoplevisible && <Choosepeople
                    peoplevisible={peoplevisible}
                    indeterminate={this.state.indeterminate}
                    plainOptions={this.state.plainOptions}
                    checkAll={this.state.checkAll}
                    checkedList={this.state.checkedList}
                    onCheckAllChange={this.onCheckAllChange}
                    onChange={this.onChange}
                    handleCancelPeoplevisible={this.handleCancelPeoplevisible}
                    handleOkPeoplevisible={this.handleOkPeoplevisible}
                    onChangepeople={this.onChangepeople}
                />}
                {detailVisible && <OnedoDetail
                    showId={showId}
                    launch={launch}
                    process={process}
                    addprocess={this.addprocess}
                    participants={participants}
                    detailData={detailData}
                    getDetail={this.getDetail}
                    modfiyDetail={this.modfiyDetail}
                    addImg={this.addImg}
                    reduceImg={this.reduceImg}
                    reduceIndex={this.reduceIndex}
                    detailVisible={detailVisible}
                    onCloseDetail={this.onCloseDetail}
                    addChildOnedo={this.addChildOnedo}
                />}
            </div >
        )
    }
    addChildOnedo=()=>{
        this.state.participants = []
        this.state.launch = []
        this.state.tempId = ''
        this.setImgInit()
        this.setState({ visible: true, workTitle: "" })
    }
    onCheckAllChange = e => {
        this.setState({
            checkedList: e.target.checked ? this.state.plainOptions.map(item => item.value) : [],
            indeterminate: false,
            checkAll: e.target.checked,
        }, () => {
            console.log(this.state.checkedList);
        });
    };

    onChange = (checkedList) => {
        this.setState({
            checkedList,
            indeterminate: !!checkedList.length && checkedList.length < this.state.plainOptions.length,
            checkAll: checkedList.length === this.state.plainOptions.length,
        }, () => {
            console.log(this.state.checkedList);
        });
    };

    changePage = (current) => {
        this.setState({ current }, () => {
            this.getOnedoList()
        })
    }
    //详情

    modfiyDetail = ({ describtion, flag, userId, startTime, endTime, title }) => {
        let data = {
            showId: this.state.showId,
            describtion: describtion,
            flag,
            userId: userId && userId.map(item => item.showId),
            startTime,
            endTime,
            title
        }
        modfiyDetail(data).then(res => {
            if (res.status === 0) {
                message.success(res.description);
            }
        })
    }

    onCloseDetail = () => {
        this.getOnedoList()
        this.setState({ detailVisible: false })
    }

}
