const querystring = require('querystring');
// const fetch = require('dva').fetch;

// let Api = 'http://10.18.28.22.:8081'//线上
// let WsApiUrl = 'ws://10.18.28.22:8081'


// let Api = 'http://192.168.50.90:8081'//本地

let WsApiUrl = 'ws://1doapi.yicall.com'

let Api = 'http://1doapi.yicall.com'//本地

export let url  = Api
export let WsApi = WsApiUrl
export default function request(URL, options) {
    let url
    // if(options.flag === "auth") {
    //      url = process.env.auth_api + URL;
    // }else{
         url = Api + URL;
    // }
    // const authtoken =  localStorage.getItem('token')
    const defaultOptions = {
        // mode: 'cors',
    };
    const newOptions = { ...defaultOptions, ...options };
    //上传文件post
    // newOptions.headers = {
    //     Authorization: `Bearer ${authtoken}`,
    //     ...newOptions.headers,
    // };
    //普通post
    if (newOptions.method.toLocaleUpperCase()  === 'POST') {
        newOptions.headers = {
            Accept: 'application/json',
            'Content-Type': 'application/json; charset=utf-8',
            // Authorization: `Bearer ${authtoken}`,
            user:localStorage.getItem('showId'),
            token:localStorage.getItem('token'),
            ...newOptions.headers,
        };
        newOptions.body = JSON.stringify(newOptions.body);
    }
    if (newOptions.method.toLocaleUpperCase()  === 'GET') {
        const param = querystring.stringify(newOptions.param)
        url = url + `?${param}`
        newOptions.headers = {
            // Authorization: `Bearer ${authtoken}`,
            user:localStorage.getItem('showId'),
            token:localStorage.getItem('token'),
            ...newOptions.headers,
        };
    }
    return fetch(url, newOptions)
        .then(checkStatus)
        .then(parseJSON)
        .then(gotoLogin)
        .catch(err => err)
}

function checkStatus(response) {
    if (response.status >= 200 && response.status < 300) {
        return response;
    }
    const error = new Error(response.statusText);
    error.response = response;
    throw error;
}

function parseJSON(response) {
    return response.json();
}

function gotoLogin(data) {
    if(data.status === 401){
    //   window.location.href = process.env.signOutUrl
    }
    return data
}
