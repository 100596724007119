import React, { Component } from 'react'
import { TextareaItem, List, Picker, ImagePicker, Button, Checkbox, Calendar, Toast } from 'antd-mobile'
import { getWorkProcess, getpeopleBywork, GetQueryString, addOnedo, addWorkflow } from '../../services/index'
import { url } from '../../utils/request'
import moment, { locale } from 'moment'
import enUS from 'antd-mobile/lib/calendar/locale/en_US';
import zhCN from 'antd-mobile/lib/calendar/locale/zh_CN';
import './add.scss'
const CheckboxItem = Checkbox.CheckboxItem;
const now = new Date();

export default class H5add extends Component {
    constructor(props) {
        super(props);

        this.state = {
            describtion: "",
            workFlow: [],
            workFlowId: '',
            participants: [],//参与人
            visible: false,
            previousParticipants: '',//上次通过工作流添加的参与人
            peopleList: [],//人员列表
            show: false,
            en: false,
            config: {},
            startTime: "",
            endTime: "",
            tempId: ""
        };
    }
    componentDidMount() {
        this.autoFocusInst.focus();
        this.getWorkProcess()
        this.getInitParticipants()
        this.checkList()


        //添加参与人进行，恢复内容
        if (localStorage.getItem('describtion')) {
            this.setState({ describtion: localStorage.getItem('describtion') })
            localStorage.removeItem('describtion')
        }
        if (localStorage.getItem('startTime')) {
            this.setState({
                startTime: localStorage.getItem('startTime'),
                endTime: localStorage.getItem('endTime')
            })
            localStorage.removeItem('startTime')
            localStorage.removeItem('endTime')
        }
        if(localStorage.getItem('workFlowId')){
            this.setState({
                workFlowId: [Number(localStorage.getItem('workFlowId'))]
            })
            localStorage.removeItem('workFlowId')
        }
    }
    checkList = () => {
        if (localStorage.getItem('checks')) {
            //需要保存之前的participants
            let participants = JSON.parse(localStorage.getItem('participants'))//添加参与人之前的参与人
            this.state.participants = [...participants, ...JSON.parse(localStorage.getItem('checks'))]
            localStorage.removeItem('checks')
        }
        this.setState({})
    }
    getInitParticipants = () => {
        this.state.participants.push({
            showId: localStorage.getItem('showId'),
        })
        this.setState({})
    }
    getWorkProcess = () => {
        let data = {
            pageNum: 1,
            pageSize: 20,
            workflowStatus: 0
        }
        getWorkProcess(data).then(res => {
            if (res.status === 0) {
                this.setState({
                    workFlow: res.data.list.map(item => ({
                        ...item,
                        label: item.workflowName,
                        value: item.id
                    }))
                }, () => {
                    // console.log(this.state.workFlow);
                })
            }
        })
    }
    deleteSameElement = () => {
        let b = this.state.previousParticipants
        let a = this.state.participants
        for (var i = 0; i < b.length; i++) {
            for (var j = 0; j < a.length; j++) {
                if (a[j].showId == b[i].showId) {
                    a.splice(j, 1);
                    j = j - 1;
                }
            }
        }
        return a;
    }
    changeWorkFlowSuccess = (v) => {
        console.log(v);

        //删除上次添加的参与人
        this.setState({
            participants: this.deleteSameElement(),
            tempId: v[0]
        }, () => {
            this.getpeopleBywork(v)
        })
    }
    getpeopleBywork = ([id]) => {
        getpeopleBywork({ id }).then(res => {
            if (res.status === 0) {
                this.state.previousParticipants = res.data
                this.state.participants = [...this.state.participants, ...res.data]
                this.setState({})
            }
        })
    }
    changeText = (describtion) => {
        this.setState({ describtion })
        localStorage.setItem('describtion', describtion)
    }
    addImg = (e) => {
        e.preventDefault();
        localStorage.setItem('participants', JSON.stringify(this.state.participants))
        this.props.history.push(`/choosePeople`);
    }
    delImg = (files, type, index) => {
        this.state.participants.splice(index, 1)
        this.setState({})
    }
    onClose = key => () => {
        // console.log('11');
        this.setState({
            visible: false
        })
    }
    onChangePeople = (val) => {
        console.log(val);
    }
    renderBtn(zh, config = {}) {
        config.locale = zhCN;
        return (
            <List.Item arrow="horizontal"
                onClick={() => {
                    document.getElementsByTagName('body')[0].style.overflowY = 'hidden';
                    this.setState({
                        show: true,
                        config
                    });
                }}
            >
                {zh}
            </List.Item>
        );
    }
    onCancel = () => {
        document.getElementsByTagName('body')[0].style.overflowY = this.originbodyScrollY;
        this.setState({
            show: false,
            startTime: undefined,
            endTime: undefined,
        });
    }
    onConfirm = (startTime, endTime) => {
        console.log(moment(startTime).format('YYYY-MM-DD'), endTime.toLocaleString());
        document.getElementsByTagName('body')[0].style.overflowY = this.originbodyScrollY;
        this.setState({
            show: false,
            startTime: moment(startTime).format('YYYY-MM-DD'),
            endTime: moment(endTime).format('YYYY-MM-DD'),
        });
        localStorage.setItem('startTime', moment(startTime).format('YYYY-MM-DD'))
        localStorage.setItem('endTime', moment(endTime).format('YYYY-MM-DD'))
    }
    add = () => {
        const { describtion, startTime, endTime, participants } = this.state
        if (!describtion) {
            Toast.info('请先填写内容！', 1);
        }
        let data = {
            createUserShowId: localStorage.getItem('showId'),
            describtion,
            startTime,
            endTime,
            participants: participants.map(item => ({ userShowId: item.showId })),
            sponsor: [{ userShowId: localStorage.getItem('showId') }],
            title: describtion,
            sourceId: localStorage.getItem('sourceId'),
            sourceEventId: localStorage.getItem('sourceEventId'),
        }
        localStorage.getItem('doId') && (data.parentId = localStorage.getItem('doId'))
        addOnedo(data).then(res => {
            if (res.status === 0) {
                localStorage.removeItem('startTime')
                localStorage.removeItem('endTime')
                if (localStorage.getItem('doId')) {
                    this.props.history.push(`/appDetail?doId=${localStorage.getItem('doId')}`);
                } else {
                    this.props.history.push(`/list`);
                }
                Toast.success('新建成功', 1);
                // addWorkflow
                if (this.state.tempId) {
                    addWorkflow({
                        showId: res.data,
                        tempId: this.state.tempId
                    }).then(res => {
                        if (res.status === 0) {
                        }
                    })
                }
                // console.log(this.state.tempId);
            }
        })
    }
    returnlist = () => {
        this.props.history.push(`/list`);

    }
    render() {
        const { describtion, workFlow, workFlowId, participants, show, startTime, endTime } = this.state
        let files = participants.map((item, index) => {
            return {
                id: index,
                url: `${url}/yidoBase/avatar?showId=${item.showId}`
            }
        })
        console.log(files);
        return (
            <div className='h5add'>
                <p className='returnIcon'><i className="iconfont icon-ffanhui-" onClick={this.returnlist}></i></p>
                <TextareaItem
                    ref={el => this.autoFocusInst = el}
                    placeholder="请输入内容"
                    className='textarea'
                    count={500}
                    value={describtion}
                    labelNumber={5}
                    rows={5}
                    onChange={this.changeText}
                />
                <div className='Picker'>
                    <Picker
                        data={workFlow}
                        title="请选择工作流"
                        extra="请选择"
                        cols={1}
                        value={workFlowId}
                        onChange={v =>{
                           localStorage.setItem('workFlowId',v)
                           this.setState({ workFlowId: v }) 
                        } }
                        onOk={v => this.changeWorkFlowSuccess(v)}
                    >
                        <List.Item arrow="horizontal">添加工作流</List.Item>
                    </Picker>
                    {this.renderBtn('选择计划时间')}
                </div>
                <Calendar
                    {...this.state.config}
                    visible={show}
                    onCancel={this.onCancel}
                    onConfirm={this.onConfirm}
                />
                <div className='planTime'>计划时间：{startTime && `${startTime} 至 ${endTime}`}</div>
                <div className='participants'>
                    <i className="iconfont icon-yonghu-fuben" />
                    <ImagePicker
                        length={8}
                        files={files}
                        onChange={this.delImg}
                        onAddImageClick={this.addImg}
                    />
                </div>
                <Button style={{
                    position: 'fixed',
                    width: '100%',
                    bottom:'0'
                }} type="primary" onClick={this.add}>确定</Button>
            </div>
        )
    }
}
