import request, { url } from '../utils/request'

//获取工作流
export function getWorkProcess(body) {
  return request(`/workflowTemplate/list`, {
    method: 'POST',
    // credentials: 'include',
    body,
  });
}
//通过工作流获取人员
export function getpeopleBywork({id}) {
  return request(`/user/getRegUserListByTempId/${id}`, {
    method: 'POST',
    // credentials: 'include',
    // body,
  });
}

export function GetQueryString(name){
  const href = window.location.href;
  const index = href.indexOf("?");
  if (index > 0 && href.indexOf("=") > 1) {
    const arrSource = href.substring(index+1, href.length).split("&");
    for(let i=0;i<arrSource.length;i++){
      if(arrSource[i].indexOf(name) == 0){
        return decodeURIComponent(arrSource[i].substring(name.length+1, arrSource[i].length))
      }
    }
  }
  return ""
}
export function regUserList(body) {
  return request(`/user/regUserList`, {
    method: 'POST',
    // credentials: 'include',
    body,
  });
}
//新建1do
export function addOnedo(body) {
  return request(`/yidoBase/add`, {
    method: 'POST',
    // credentials: 'include',
    body,
  });
}
//获取来源系统和事件类型列表
export function sourceList(body) {
  return request(`/yidoBase/sourceList`, {
    method: 'POST',
    // credentials: 'include',
    body,
  });
}
//添加工作流
export function addWorkflow(body) {
  return request(`/workflow/add`, {
    method: 'POST',
    // credentials: 'include',
    body,
  });
}
//获取详情
export function getDeatil(param) {
  return request(`/yidoBase/detail`, {
    method: 'GET',
    // credentials: 'include',
    param,
  });
}
//1do工单新增标签
export function addLabel(body) {
  return request(`/yidoBase/addLabel`, {
    method: 'POST',
    // credentials: 'include',
    body,
  });
}
//1do工单删除标签
export function deleteLabel(body) {
  return request(`/yidoBase/deleteLabel`, {
    method: 'POST',
    // credentials: 'include',
    body,
  });
}
//1do工单详情修改接口
export function modfiyDetail(body) {
  return request(`/yidoBase/modfiyDetail`, {
    method: 'POST',
    // credentials: 'include',
    body,
  });
}
//修改1do工单状态(办结和删除)
export function updateStatus(body) {
  return request(`/yidoBase/updateStatus`, {
    method: 'POST',
    // credentials: 'include',
    body,
  });
}
//获取工作流（详情）
export function getworkflow(body) {
  return request(`/workflow/detail`, {
    method: 'POST',
    // credentials: 'include',
    body,
  });
}
//获取1do列表
export function getOnedoList(body) {
  return request(`/yidoBase/list`, {
    method: 'POST',
    // credentials: 'include',
    body,
  });
}
//获取评论
export function getComments(param) {
  return request(`/yidoBase/getComments`, {
    method: 'GET',
    // credentials: 'include',
    param,
  });
}
// 删除评论
export function deleteComment(param) {
  return request(`/yidoBase/deleteComment`, {
    method: 'GET',
    // credentials: 'include',
    param,
  });
}
//评论
export function setComment(body) {
  return request(`/yidoBase/comment`, {
    method: 'POST',
    // credentials: 'include',
    body,
  });
}
//钉钉鉴权
export function getDingToken(param) {
  return request(`/yidoBase/getToken`, {
    method: 'GET',
    // credentials: 'include',
    param,
  });
}
//系统注册
export function systemRegistration(body) {
  return request(`/yidoBase/systemRegistration`, {
    method: 'POST',
    // credentials: 'include',
    body,
  });
}
//钉钉鉴权
export function getDingDingToken(body) {
  return request(`/yidoBase/getToken`, {
    method: 'POST',
    // credentials: 'include',
    body,
  });
}