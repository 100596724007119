import React from 'react';
import ReactDOM from 'react-dom';
import '../src/assets/css/icon.css';
import '../src/assets/css/whole.css';
import App from './pages/home';
import moment from 'moment'
import reportWebVitals from './reportWebVitals';
import { BrowserRouter as Router } from "react-router-dom";
import { LocaleProvider } from 'antd';
import zhCN from 'antd/es/locale/zh_CN'
import 'moment/locale/zh-cn'
import RouteIndex from './router/index'
// import { Provider } from 'react-redux'
// import store from './store/index'

ReactDOM.render(
    <LocaleProvider locale={zhCN}>
       {/* <Provider store = {store}> */}
         <RouteIndex />
       {/* </Provider> */}
    </LocaleProvider>
  ,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
