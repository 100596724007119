import React, { Component } from 'react'
import { Input, Icon, Button, Upload,message, DatePicker, TimePicker } from 'antd';
import moment from 'moment';
import { url } from '../../utils/request'
import { GetQueryString,getWorkProcess,getpeopleBywork,regUserList,addOnedo,addWorkflow } from '../../services/index'
import WorkFlow from '../../components/workFlow'
import Choosepeople from '../../components/choosepeople'
import './index.scss'
const { RangePicker } = DatePicker;
const { TextArea } = Input;

export default class OnedoAdd extends Component {
    constructor(props) {
        super(props);
        this.state = {
            describtion: '',
            workTitle: '',//工作流标题
            planTimestart: null,
            planTimeend: null,
            fileList: [],
            visible: true,
            launch: [],
            participants: [],
            workTitle: '',
            Modalvisible: false,
            peoplevisible:false,
            workList:'',
            indeterminate: true,
            plainOptions: [],
            checkAll: false,
            checkedList: [],

        }
    }
    componentDidMount() {
        localStorage.setItem('showId', GetQueryString('showId'))
        localStorage.setItem('token',GetQueryString('token'))
        localStorage.setItem('sourceEventId',GetQueryString('sourceEventId'))
        localStorage.setItem('sourceId',GetQueryString('sourceId'))
        this.state.launch.push({ showId : GetQueryString('showId')})
        this.state.participants.push({ showId : GetQueryString('showId')})
        this.setState({})
    }
    onChangeInput = (e) => {
        this.setState({ describtion: e.target.value })
    }
    changeTime = (dates, dateStrings) => {
        this.setState({
            planTimestart: dateStrings[0],
            planTimeend: dateStrings[1],
        })
    }
    handleChangeFile = (info) => {
        if (info.file.status !== 'uploading') {
            this.setState({ fileList: info.fileList.map(item => ({ annexUrl: item.response.data, annexName: item.name })) })
        }
    }
    onClose = () => {
        window.parent.postMessage({ type: false }, "*");
    }
    addprocess = () => {
        this.setState({ Modalvisible: true })
        this.getWorkProcess()
    }
    getWorkProcess() {
        let data = {
            pageNum: 1,
            pageSize: 20,
            workflowStatus: 0
        }
        getWorkProcess(data).then(res => {
            if (res.status === 0) {
                this.setState({ workList: res.data.list })
            }
        })
    }
    reduceIndex = (index) => {
        console.log(this.state.participants[index]);
        //详情里删除人员，
        // if (this.state.detailVisible) {
        //     this.modfiyDetail({ flag: 2, userId: [this.state.participants[index]] })
        // }
        // ------------
        this.state.participants.splice(index, 1)
        console.log(this.state.participants);
        this.setState({})
    }
    addImg = () => {
        this.setState({ peoplevisible: true, checkedList: [], })
        this.getpeople()
    }
    getpeople = (word = '') => {
        let data = {
            "pageNum": 1,
            "pageSize": 100,
            word
        }
        regUserList(data).then(res => {
            if (res.status === 0) {
                this.setState({
                    plainOptions: res.data.list.map(item => ({ label: item.name, value: item.showId }))
                })
            }
        })
    }
    reduceImg = () => {
        this.state.participants = this.state.participants.map(item => {
            return { ...item, show: !item.show }
        })
        console.log(this.state.participants)
        this.setState({})
    }
    submit = () => {
        if (!this.state.describtion) {
            message.warning('内容不能为空！')
            return;
        }
        if (this.state.participants.length <= 0) {
            message.warning('参与人不能为空！')
            return;
        }
        const { launch, participants,describtion,planTimeend,planTimestart,fileList } = this.state
        let data = {
            createUserShowId: launch[0].showId,
            describtion,
            title: describtion,
            endTime: planTimeend,
            startTime: planTimestart,
            file: fileList,
            participants: participants.map(item => ({ userShowId: item.showId })),
            sponsor: launch.map(item => ({ userShowId: item.showId })),
            sourceEventId: localStorage.getItem('sourceEventId'),
            sourceId: localStorage.getItem('sourceId')
        }
        addOnedo(data).then(res => {
            if (res.status === 0) {
                message.success(res.description);
                // this.setState({ visible: false })
                setTimeout(()=>{
                     window.parent.postMessage({ type: false }, "*");
                },500)
                this.state.tempId && this.addWorkflow(res.data)
            }
        })
    }
    //详情中添加工作流有 peopleList
    addWorkflow(showId, peopleList) {
        let data = {
            showId,
            tempId: this.state.tempId
        }
        addWorkflow(data).then(res => {
            if (res.status === 0) {
                // if (this.state.detailVisible) {
                //     //peopleList : 添加工作流获取的人员列表
                //     //需要调修改参与人接口
                //     this.modfiyDetail({ flag: 1, userId: peopleList })

                //     this.setState({
                //         launch: [],
                //         participants: [],
                //     }, () => {
                //         this.getDetail(this.state.showId)
                //         this.getworkflow(this.state.showId)
                //     })

                // }
            }
        })
    }
    render() {
        const { describtion, planTimestart, planTimeend, fileList, launch, participants, workTitle, Modalvisible,workList,peoplevisible} = this.state
        const props = {
            name: 'file',
            action: `${url}/upload`,
            headers: {
            },
            onChange: this.handleChangeFile,
        };
        return (
            <div className='addSlider'>
                <div className='title'>新建1do
                <Icon type="close"  onClick={this.onClose}/>
                </div>
                <div className='toptitle'>
                    {launch && launch.map((item, index) => {
                        return <img key={index} src={`${url}/yidoBase/avatar?showId=${item.showId}`} />
                    })}
                    <TextArea rows={4} value={describtion} onChange={(e) => { this.onChangeInput(e) }} className='TextArea' />
                </div>
                <div className='process'>
                    <span>流程</span>
                    {workTitle ? workTitle : <Button type="link" onClick={this.addprocess}>添加工作流</Button>}
                </div>
                <div className='imgaction'>
                    <Icon type="user" className='user' />
                    <div className='imglist'>
                        {participants && participants.map((item, index) => {
                            return <span className='imgspan' key={index}>
                                <img src={`${url}/yidoBase/avatar?showId=${item.showId}`} />
                                {item.show && <Icon onClick={() => { this.reduceIndex(index) }} className="minus" type="minus-circle" />}
                            </span>
                        })}
                    </div>
                    <div className='actions'>
                        <Icon type="plus-circle" onClick={this.addImg} />
                        <Icon type="minus-circle" onClick={this.reduceImg} />
                    </div>
                </div>
                <div className='createTime'>
                    <span className="title">创建时间</span>
                    <DatePicker defaultValue={moment(moment().format('yyyy-MM-DD'), 'yyyy-MM-DD')} disabled />
                    <TimePicker defaultValue={moment(moment().format('HH:mm:ss'), 'HH:mm:ss')} disabled />
                </div>
                <div className='planTime'>
                    <span className="title">计划时间</span>
                    <RangePicker
                        allowClear
                        value={[planTimestart ? moment(planTimestart) : null, planTimeend ? moment(planTimeend) : null]}
                        onChange={(dates, dateStrings) => { this.changeTime(dates, dateStrings) }}
                    />
                </div>
                <div className='upload'>
                    <Upload {...props}>
                        <Button>
                            <Icon type="upload" /> 上传附件
                            </Button>
                    </Upload>
                </div>
                <div className='buttons'>
                    <Button onClick={this.onClose}>取消</Button>
                    <Button type='primary' onClick={() => { this.submit(describtion, fileList, planTimestart, planTimeend) }}>确定</Button>
                </div>
                {Modalvisible && <WorkFlow
                    Modalvisible={Modalvisible}
                    workList={workList}
                    addWork={this.addWork}
                    handleOk={this.handleOk}
                    handleCancel={this.handleCancel}
                />}
                {peoplevisible && <Choosepeople
                    peoplevisible={peoplevisible}
                    indeterminate={this.state.indeterminate}
                    plainOptions={this.state.plainOptions}
                    checkAll={this.state.checkAll}
                    checkedList={this.state.checkedList}
                    onCheckAllChange={this.onCheckAllChange}
                    onChange={this.onChange}
                    handleCancelPeoplevisible={this.handleCancelPeoplevisible}
                    handleOkPeoplevisible={this.handleOkPeoplevisible}
                    onChangepeople={this.onChangepeople}
                />}
            </div>
        )
    }
    addWork = (id, workTitle) => {
        getpeopleBywork({ id }).then(res => {
            if (res.status === 0) {
                this.state.participants = [...this.state.participants, ...res.data]
                this.setState({ Modalvisible: false, workTitle, tempId: id }, () => {

                    //详情时候需要调/workflow/add,传工单的showid
                    // if (this.state.detailVisible) {
                    //     this.addWorkflow(this.state.showId, res.data)
                    // }

                })
            }
        })
    }
    handleOk = () => {
        this.setState({ Modalvisible: false })
    }
    handleCancel = () => {
        this.setState({ Modalvisible: false })
    }
    onCheckAllChange = e => {
        this.setState({
            checkedList: e.target.checked ? this.state.plainOptions.map(item => item.value) : [],
            indeterminate: false,
            checkAll: e.target.checked,
        }, () => {
            console.log(this.state.checkedList);
        });
    };

    onChange = (checkedList) => {
        this.setState({
            checkedList,
            indeterminate: !!checkedList.length && checkedList.length < this.state.plainOptions.length,
            checkAll: checkedList.length === this.state.plainOptions.length,
        }, () => {
            console.log(this.state.checkedList);
        });
    };
    handleCancelPeoplevisible = () => {
        this.setState({ peoplevisible: false })
    }
    handleOkPeoplevisible = () => {
        let peoples = this.state.checkedList.map(item => ({ showId: item }))
        this.state.participants = this.unique([... this.state.participants, ...peoples]) //需要对participants去重
        this.setState({ peoplevisible: false })
        // ------------
        //详情里添加人员，只传递了新增的人员
        // if (this.state.detailVisible) {
        //     this.modfiyDetail({ flag: 1, userId: peoples })
        // }
    }
    unique = (arr) => {
        var array = [];
        for (var i = 0; i < arr.length; i++) {
            if (array.length > 0) {
                if (array.map(item => item.showId).indexOf(arr[i].showId) === -1) {
                    array.push(arr[i])
                }
            } else {
                array.push(arr[0])
            }
        }
        return array;
    }
    onChangepeople = (e) => {
        if (this.interal) {
            clearTimeout(this.interal)
        }
        this.interal = setTimeout(() => {
            this.getpeople(e.target.value)
        }, 500)
    }
}
