import React, { Component } from 'react'
import { Input, Icon, Button, message, Drawer, DatePicker, } from 'antd';
import './onedoDetail.scss'
import { url } from '../utils/request'
import moment from 'moment';
import { addLabel, deleteLabel, updateStatus } from '../services/index'
const { RangePicker } = DatePicker;

const { TextArea } = Input;

export default class OnedoDetail extends Component {
    constructor(props) {
        super(props);
        this.state = {
            tagname: '',
            labellist: [],
            showId: '',
            participants: [],
            describtion: '',
            startTime: null,
            endTime: null,
            title: "",
            status: '',
            processTitle: '',
            nodes: []
        }
    }
    componentDidMount() {
    }
    componentWillReceiveProps(nextProps) {
        // console.log(nextProps);
        this.setState({ labellist: nextProps.detailData.labelList })
        this.setState({ showId: nextProps.showId })
        this.setState({ participants: nextProps.detailData.userNameList })
        this.setState({ describtion: nextProps.detailData.describtion })
        this.setState({ startTime: nextProps.detailData.startTime })
        this.setState({ endTime: nextProps.detailData.endTime })
        this.setState({ title: nextProps.detailData.title })
        this.setState({ status: nextProps.detailData.status })

       //流程
        this.setState({processTitle :nextProps.process? nextProps.process.entity.tempName :'' })
        this.setState({nodes : nextProps.process?nextProps.process.nodes:''})
    }
    onChangeInput = (e) => {
        this.setState({ describtion: e.target.value }, () => {
            this.props.modfiyDetail({ describtion: e.target.value })
        })
    }
    onChangeInput1 = (e) => {
        this.setState({ describtion: e.target.value })
    }
    onChangeTags = (e) => {
        this.setState({ tagname: e.target.value })
    }
    addtags = () => {
        let data = {
            labelName: this.state.tagname,
            showId: this.props.showId
        }
        addLabel(data).then(res => {
            if (res.status === 0) {
                this.setState({ tagname: "" })
                this.state.labellist.push(res.data)
                this.setState({})
                message.success(res.description);
            }else{
                message.warning(res.description);
            }
        })

    }
    reduceTags = (id, index) => {
        deleteLabel({ id }).then(res => {
            if (res.status === 0) {
                this.state.labellist.splice(index, 1)
                message.success(res.description);
                this.setState({})
            }
        })
    }
    changeTime = (dates, dateStrings) => {
        this.setState({
            startTime: dateStrings[0],
            endTime: dateStrings[1],
        }, () => {
            this.props.modfiyDetail({ startTime: dateStrings[0], endTime: dateStrings[1] })
        })
    }
    onChangeTitle = (e) => {
        this.setState({ title: e.target.value })
    }
    updataTitle = () => {
        this.props.modfiyDetail({ title: this.state.title })
    }
    conclude = () => {
        let data = {
            showId: this.props.showId,
            status: 1
        }
        updateStatus(data).then(res => {
            if (res.status === 0) {
                message.success(res.description);
                this.setState({ status: 3 })
            }
        })
    }
    getStatus=({clazz,nodeStatus})=>{
       if(clazz === 'start'){
           return '发起'
       }else if(clazz === 'userTask'){
           if(nodeStatus === 0){
            return '待审批'
           }else if(nodeStatus === 1){
            return '进行中'
           }else if(nodeStatus === 2){
            return '已完成'
           }
       }
    }
    render() {
        const { detailVisible, onCloseDetail, launch, addImg, reduceImg, reduceIndex, participants,addprocess } = this.props
        const { tagname, labellist, describtion, startTime, endTime, title, status, processTitle, nodes } = this.state
        return (
            <div className='onedetail'>
                <Drawer
                    title="1do详情"
                    placement="right"
                    closable={true}
                    width={'40%'}
                    className='addonedo'
                    onClose={onCloseDetail}
                    visible={detailVisible}
                >
                    <div className='toptitle detailTitle'>
                        {launch && launch.map((item, index) => {
                            return <img key={index} src={`${url}/yidoBase/avatar?showId=${item.showId}`} />
                        })}
                        <TextArea
                            value={describtion}
                            rows={4}
                            onChange={(e) => { this.onChangeInput1(e) }}
                            onBlur={(e) => { this.onChangeInput(e) }}
                            className='TextArea' />
                    </div>
                    <div className='labelDetail'>
                        <span className='title'>标签</span>
                        {labellist && labellist.map((item, index) => {
                            return <span className='tags' key={item.id}>{item.labelName}<Icon type="close" onClick={() => { this.reduceTags(item.id, index) }} /></span>
                        })}
                        <Input value={tagname} onPressEnter={this.addtags} onChange={(e) => { this.onChangeTags(e) }} style={{ width: '80%' }} />
                        <Button type="primary" onClick={this.addtags}>添加</Button>
                    </div>
                    <div className='processDetail'>
                        <span className='title'>流程</span>
                        <div className='rightData'>
                            <p>{processTitle}</p>
                            <div>
                                {nodes ? nodes.map((item, index) => {
                                    console.log(index);
                                    if (item.clazz === 'endGreen') {
                                        return <span key={index} className='finishspan'>结束</span>
                                    } else {
                                        return <span key={index} className='content'>
                                            {item.approvalUser.length > 0 && item.approvalUser.map((v,i) => {
                                                return <span  key={i} >
                                                    <img src={`${url}/yidoBase/avatar?showId=${v.showId}`} />
                                                    <Icon type="arrow-right" />
                                                    <span className='trip'>{this.getStatus(item)}</span>
                                                </span>
                                            })}
                                        </span>
                                    }
                                }) : <Button type="link" onClick={addprocess}>添加工作流</Button>}
                            </div>
                        </div>

                    </div>
                    <div className='imgaction detailImg'>
                        <Icon type="user" className='user' />
                        <div className='imglist'>
                            {participants && participants.map((item, index) => {
                                return <span className='imgspan' key={index}>
                                    <img src={`${url}/yidoBase/avatar?showId=${item.showId}`} />
                                    {item.show && <Icon onClick={() => { reduceIndex(index) }} className="minus" type="minus-circle" />}
                                </span>
                            })}
                        </div>
                        <div className='actions'>
                            <Icon type="plus-circle" onClick={addImg} />
                            <Icon type="minus-circle" onClick={reduceImg} />
                        </div>
                    </div>
                    <div className='planTime'>
                        <span className="title">计划时间</span>
                        <RangePicker
                            allowClear
                            value={[startTime ? moment(startTime) : null, endTime ? moment(endTime) : null]}
                            onChange={(dates, dateStrings) => { this.changeTime(dates, dateStrings) }}
                        />
                    </div>
                    <div className='titleData'>
                        <span className="title">标题</span>
                        <Input value={title} onChange={(e) => { this.onChangeTitle(e) }} style={{ width: '60%' }} />
                        <Button type="primary" onClick={this.updataTitle}>完成</Button>
                    </div>
                    <div className='handle'>
                        <Button onClick={this.props.addChildOnedo}>新建下级1do</Button>
                        <Button type="primary" disabled={status === 3 ? true : false} onClick={this.conclude}>{status === 3 ? '已办结' : "办结"}</Button>
                    </div>
                </Drawer>
            </div>
        )
    }
}
